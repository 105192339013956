import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, concatMap, debounceTime, distinctUntilChanged, Observable, startWith, tap } from 'rxjs';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class TaskUserDropdownComponentService {
  isMention = false;
  taskIds: number[] = [];

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  searchUserControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  searchUserValueChanges$: Observable<User[]> = this.searchUserControl.valueChanges.pipe(
    startWith(''),
    distinctUntilChanged(),
    debounceTime(300),
    tap(() => this.isLoading.next(true)),
    concatMap((value) =>
      this.isMention
        ? this.userStateService.getUsers([], value)
        : this.userStateService.getAssignableUsers(this.taskIds, value),
    ),
    tap(() => this.isLoading.next(false)),
  );

  constructor(private readonly userStateService: UserStateService) {}
}
