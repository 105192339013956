<input
  #inputSearch
  (keydown)="interceptKeydown($event)"
  [formControl]="taskUserDropdownComponentService.searchUserControl"
  class="input-search py-4 px-8"
  dougsAutofocus
  dougsFormFieldControl
  placeholder="Rechercher..."
/>
<dougs-loader *ngIf="taskUserDropdownComponentService.isLoading$ | async"></dougs-loader>
<ng-container *ngIf="taskUserDropdownComponentService.searchUserValueChanges$ | async as users">
  <p *ngIf="!(taskUserDropdownComponentService.isLoading$ | async) && users.length === 0" class="small p-8">
    Aucun résultat
  </p>
  <ng-container *ngIf="!(taskUserDropdownComponentService.isLoading$ | async)">
    <ng-container>
      <dougs-dropdown-option
        [closeOnClick]="true"
        (click)="onSelectUser(null)"
        *ngIf="shouldShowUnassign"
        [ngClass]="{ active: !activeUser?.id }"
        [value]="null"
      >
        <i class="fal fa-times-circle mr-4"></i>
        Assigner à personne
      </dougs-dropdown-option>
      <dougs-dropdown-option
        [closeOnClick]="true"
        (click)="onSelectUser(userStateService.loggedInUser)"
        *ngIf="shouldShowMe"
        [ngClass]="{ active: activeUser?.id === (userStateService.loggedInUser$ | async).id }"
        [value]="userStateService.loggedInUser$ | async"
      >
        <dougs-avatar
          [image]="
            (userStateService.loggedInUser$ | async).profile?.avatarUrl ||
            (userStateService.loggedInUser$ | async).profile?.alternateAvatarUrl
          "
          class="mr-4 avatar"
          size="small"
        ></dougs-avatar>
        Assigner à moi
      </dougs-dropdown-option>
    </ng-container>
    <dougs-dropdown-option
      [closeOnClick]="true"
      (click)="onSelectUser(user)"
      *ngFor="let user of users; trackBy: 'value.id' | trackBy"
      [ngClass]="{ active: activeUser?.id === user.id }"
      [value]="user"
    >
      <dougs-avatar
        [image]="user.profile?.avatarUrl || user.profile?.alternateAvatarUrl"
        class="mr-4 avatar"
        size="small"
      ></dougs-avatar>
      {{ user.profile?.fullName }}
    </dougs-dropdown-option>
  </ng-container>
</ng-container>
