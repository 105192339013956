import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
  AutofocusDirective,
  AvatarComponent,
  ControlFormFieldDirective,
  DropdownComponent,
  DropdownOptionComponent,
  LoaderComponent,
  TrackByPipe,
} from '@dougs/ds';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { TaskUserDropdownComponentService } from '../../../../services/control-panel/task/task-user-dropdown.component.service';

@Component({
  selector: 'dougs-task-user-dropdown',
  templateUrl: './task-user-dropdown.component.html',
  styleUrls: ['./task-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    AutofocusDirective,
    ControlFormFieldDirective,
    ReactiveFormsModule,
    NgIf,
    LoaderComponent,
    DropdownOptionComponent,
    NgClass,
    AvatarComponent,
    NgFor,
    AsyncPipe,
    TrackByPipe,
  ],
  providers: [TaskUserDropdownComponentService],
})
export class TaskUserDropdownComponent implements AfterViewInit, OnDestroy {
  public activeUser: User | null = null;
  public displayedUsers: User[] = [];

  @Input() shouldShowMe = false;
  @Input() shouldShowUnassign = false;
  @Output() selectUser: EventEmitter<User | null> = new EventEmitter<User | null>();
  @ViewChild('inputSearch') inputSearch!: ElementRef;
  @ViewChildren(DropdownOptionComponent)
  dropdownOptionsComponent: QueryList<DropdownOptionComponent> = new QueryList<DropdownOptionComponent>();
  private dropdownComponentSubscription!: Subscription;

  constructor(
    public readonly userStateService: UserStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly parent: DropdownComponent,
    public readonly taskUserDropdownComponentService: TaskUserDropdownComponentService,
  ) {}

  private _isMention = false;

  get isMention(): boolean {
    return this._isMention;
  }

  @Input()
  set isMention(isMention: boolean) {
    this._isMention = isMention;
    this.taskUserDropdownComponentService.isMention = isMention;
  }

  private _taskIds!: number[];

  @Input()
  set taskIds(taskIds: number[]) {
    this._taskIds = taskIds;
    this.taskUserDropdownComponentService.taskIds = taskIds;
  }

  get taskIds(): number[] {
    return this._taskIds;
  }

  ngAfterViewInit(): void {
    this.dropdownComponentSubscription = this.dropdownOptionsComponent.changes
      .pipe(startWith(this.dropdownOptionsComponent))
      .subscribe((dropdownOptionsComponent: QueryList<DropdownOptionComponent>) => {
        this.displayedUsers = dropdownOptionsComponent.reduce(
          (acc: User[], dropdownOptionComponent) =>
            dropdownOptionComponent.value ? acc.concat(dropdownOptionComponent.value) : acc,
          [],
        );
        this.setDefaultActiveUser();
        this.parent.updateOverlayPosition();
      });
  }

  interceptKeydown(event: KeyboardEvent): void {
    if (this.isEscapeKey(event.key)) {
      this.hideDropdown();
      event.stopPropagation();
    }
    if (this.activeUser || this.shouldShowUnassign) {
      if (this.isOpenKey(event.key)) {
        this.onSelectUser(this.activeUser);
        this.hideDropdown();
        event.preventDefault();
      } else if (this.isArrowDownKey(event.key)) {
        const index: number = this.activeUser ? this.displayedUsers.indexOf(this.activeUser) : -1;
        if (index < this.displayedUsers?.length - 1) {
          this.activeUser = this.displayedUsers[index + 1];
        }
        event.preventDefault();
      } else if (this.isArrowUpKey(event.key)) {
        const index: number = this.activeUser ? this.displayedUsers.indexOf(this.activeUser) : -1;
        if (index > (this.shouldShowUnassign ? -1 : 0)) {
          this.activeUser = this.displayedUsers[index - 1];
        }
        event.preventDefault();
      }
    }
  }

  onSelectUser(user: User | null): void {
    this.selectUser.emit(user);
  }

  isOpenKey(key: string): boolean {
    return ['Enter'].includes(key);
  }

  isEscapeKey(key: string): boolean {
    return ['Esc', 'Escape'].includes(key);
  }

  isArrowUpKey(key: string): boolean {
    return ['ArrowUp', 'Up'].includes(key);
  }

  isArrowDownKey(key: string): boolean {
    return ['ArrowDown', 'Down'].includes(key);
  }

  ngOnDestroy(): void {
    this.dropdownComponentSubscription?.unsubscribe();
  }

  private setDefaultActiveUser(): void {
    if (this.shouldShowMe && this.taskUserDropdownComponentService.searchUserControl.value && this.displayedUsers[1]) {
      this.activeUser = this.displayedUsers[1];
    } else if (
      this.shouldShowUnassign &&
      !this.shouldShowMe &&
      this.taskUserDropdownComponentService.searchUserControl.value &&
      this.displayedUsers?.length
    ) {
      this.activeUser = this.displayedUsers[0];
    } else if (this.shouldShowUnassign) {
      this.activeUser = null;
    } else {
      this.activeUser = this.displayedUsers[0] ?? null;
    }
    this.cdr.detectChanges();
  }

  private hideDropdown(): void {
    this.parent.hide();
  }
}
